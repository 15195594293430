<script setup lang="ts">
import type { PageLayoutProps } from '@/types'

const props = defineProps<PageLayoutProps>()

if (document) {
  useHead({
    script: [
      {
        innerHTML: `document.tidioChatOnSite = true;`,
      },
      {
        src: 'https://widget-v4.tidiochat.com/code/vm8wvaeic7kl9h2hjihnwahebyuhrbvw.js',
        async: true,
        defer: true,
      },
    ],
  })
}

const coloredTitle = computed(() => {
  const words = props.title.split(' ')

  if (words.length > 2) {
    const first = words.slice(0, -2).join(' ')
    const last = words.slice(-2).join(' ')

    return [first, last]
  }
  else {
    return [props.title, '']
  }
})
</script>

<template>
  <div>
    <div class="bg h-full flex items-center justify-center pt-12 px-8 mb-8">
      <UContainer class="flex flex-col lg:flex-row items-center max-w-screen-xl lg:items-start gap-12">
        <section class="space-y-8 flex-1 mt-12">
          <h1 class="text-4xl text-balance font-bold text-gray-950 tracking-tight leading-snug">
            {{ coloredTitle[0] }}
            <span class="bg-gradient-to-tr from-primary to-gray-950 bg-clip-text text-transparent">{{
              coloredTitle [1] }}</span>
          </h1>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="text-lg text-balanced font-medium text-gray-600 tracking-tight leading-snug"
            v-html="exchangeChat?.pre"
          />

          <dl
            v-if="exchangeChat?.stats"
            class="my-6 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4"
          >
            <div
              v-for="stat in exchangeChat?.stats"
              :key="stat.top"
              class="flex flex-col bg-gray-400/5 p-8"
            >
              <dt class="text-sm font-semibold leading-6 text-gray-600">
                {{ stat.bottom }}
              </dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
                {{ stat.top }}
              </dd>
            </div>
          </dl>

          <UPageBody prose>
            <div v-html="exchangeChat?.description" />
          </UPageBody>
        </section>
        <div>
          <div class="container">
            <section class="left" />
            <section class="right" />
          </div>
        </div>
      </UContainer>
    </div>

    <template v-if="exchangeChat?.section?.content">
      <ULandingSection
        class="bg-gray-900 dark"
        :title="exchangeChat?.section?.title"
        :description="exchangeChat?.section?.description"
        align="center"
      >
        <UPageBody
          prose
          :ui="{ wrapper: 'mt-0 pb-0' } "
        >
          <div
            v-html="exchangeChat?.section?.content"
          />
        </UPageBody>
      </ULandingSection>
    </template>
  </div>
</template>

<style>
.bg {
  background-image: url('/gradient.png');
  background-repeat: repeat-x;
  background-position: center top;
}
</style>
